<template>
  <card-doughnut-component
    :chartdata="data"
    :title="'Cantidad de envios por Provincia'"
    :loading="loading"
  />
</template>

<script>
import CardDoughnutComponent from "./CardDoughnutComponent";
import DashboardService from "@/services/DashboardService";

export default {
  name: "by-province",
  props: {
    year: Number,
    month: Number,
    client: Number,
  },
  data() {
    return {
      data: {},
      loading: true,
    };
  },
  components: {
    CardDoughnutComponent,
  },
  watch: {
    year: function () {
      this.loadData();
    },
    month: function () {
      this.loadData();
    },
    client: function () {
      this.loadData();
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      DashboardService.statisticsByProvince(
        this.year,
        this.month,
        this.client
      ).then(({ data }) => {
        this.loading = false;
        const elements = data.data.client;
        data.data.point.forEach((element) => {
          const item = elements.find((d) => d.province === element.province);
          if (item) {
            item.total = item.total + element.total;
          } else {
            elements.push(element);
          }
        });

        let newElements = elements
          .filter((d) => d.total > 15)
          .filter((d) => d.province !== null)
          .filter((d, index) => {
            if (index < 9) {
              return d;
            }
          });

        let arrayNew = elements.map((d, index) => {
          if (index > 9) {
            return d.total;
          }
          return 0;
        });

        const reducer = (accumulator, curr) => accumulator + curr;

        if (arrayNew > 0) {
          newElements.push({
            province: "otros",
            total: arrayNew.reduce(reducer),
          });
        }

        if (elements.length < 9) {
          newElements = elements;
        }

        this.data = {
          labels: newElements.map((d) => d.province),
          datasets: [
            {
              data: newElements.map((d) => d.total),
              backgroundColor: [
                "#ff6384",
                "#4bc0c0",
                "#ffcd56",
                "#c9cbcf",
                "#36a2eb",
                "#EF7D21",
                "#2ECC40",
                "#21440F",
                "#7FDBFF",
                "#B10DC9",
                "#FF4136",
                "#90FF36",
                "#C72A00",
                "#39C700",
                "#4A00FF",
                "#C04BC0",
                "#4B86C0",
                "#0F2144",
                "#A37FFF",
                "#7F9BFF",
                "#0F4432",
                "#440F34",
                "#3C440F",
                "#76C04B",
                "#C05A4B",
                "#C0954B",
                "#C04B50",
              ],
            },
          ],
        };
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
