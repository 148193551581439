<template>
  <card-doughnut-component
    :chartdata="data"
    :title="'Cantidad de envios por Canal'"
    :loading="loading"
  />
</template>

<script>
import CardDoughnutComponent from "./CardDoughnutComponent";
import DashboardService from "@/services/DashboardService";
export default {
  name: "by-channel",
  props: {
    year: Number,
    month: Number,
    client: Number
  },
  data() {
    return {
      data: {},
      loading: true
    };
  },
  components: {
    CardDoughnutComponent
  },
  methods: {
    loadData() {
      this.loading = true;
      DashboardService.statisticsByChannel(this.year, this.month, this.client).then(
        ({ data }) => {
          this.loading = false;
          const elements = data.data;
          this.data = {
            labels: elements.map(d => d.channel),
            datasets: [
              {
                data: elements.map(d => d.count),
                backgroundColor: [
                  "#FF5A84",
                  "#4BC0C0",
                  "#FFCD56",
                  "#2D8CE2",
                  "#36A2EB"
                ]
              }
            ]
          };
        }
      );
    }
  },
  watch: {
    year: function() {
      this.loadData();
    },
    month: function() {
      this.loadData();
    },
    client: function() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  }
};
</script> 