<template>
  <b-card
    no-body
    style=" background: rgb(249,177,21); background: linear-gradient(90deg, rgba(249,177,21,1) 0%, rgba(221,160,27,1) 50%, rgba(200,143,19,1) 100%);"
  >
    <div class="p-1">
      <h4 class="text-white mb-0">{{total | currency('', '.','', '','',) }}</h4>
      <p class="text-white">Total de Tiendas Post Pago</p>
      <line-total
        chartId="card-chart-post-pago"
        :height="80"
        :datasets="datasets"
        :labels="labels"
        v-if="datasets"
      />
    </div>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import LineTotal from "./LineTotal";

export default {
  name: "PostPagoTotal",
  components: { LineTotal },
  data() {
    return {
      total: 0,
      datasets: null,
      labels: null
    };
  },
  mounted() {
    DashboardService.staticsUserPostPagoTotal()
      .then(({ data }) => {
        const { total, months } = data.data;
        this.total = total;
        this.datasets = [
          {
            label: "post-pago",
            backgroundColor: "transparent",
            borderColor: "rgba(255,255,255,.55)",
            data: months.map(d => d.total)
          }
        ];
        this.labels = months.map(d => d.month);
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style>
</style>