<template>
    <b-card v-if="status" class="p-1">
      <b style="font-size: 18px">Estado de órdenes</b>
      <b-row
        class="my-2 mx-3 d-flex align-items-center justify-content-between"
      >
        <b-col>
          <b-row class="flex-nowrap">
            <div
              class="border border-primary initial-badge mx-1 cursor-pointer"
            >
              <feather-icon icon="CheckSquareIcon" size="21" />
            </div>
            <div
              class="d-flex flex-column user-nav profile-general"
              style="margin-top: 5px"
            >
              <p class="user-name font-weight-bolder mb-0">
                {{ status.porEnviar }}
              </p>
              <span class="user-status">Por Enviar</span>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="flex-nowrap">
            <div
              class="border border-primary initial-badge mx-1 cursor-pointer"
            >
              <feather-icon icon="SendIcon" size="21" />
            </div>
            <div
              class="d-flex flex-column user-nav profile-general"
              style="margin-top: 5px"
            >
              <p class="user-name font-weight-bolder mb-0">
                {{ status.enCurso }}
              </p>
              <span class="user-status">En Curso</span>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="flex-nowrap">
            <div
              class="border border-primary initial-badge mx-1 cursor-pointer"
            >
              <feather-icon icon="BoxIcon" size="21" />
            </div>
            <div
              class="d-flex flex-column user-nav profile-general"
              style="margin-top: 5px"
            >
              <p class="user-name font-weight-bolder mb-0">
                {{ status.entregadas }}
              </p>
              <span class="user-status">Entregadas</span>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="flex-nowrap">
            <div
              class="border border-primary initial-badge mx-1 cursor-pointer"
            >
              <feather-icon icon="XIcon" size="21" />
            </div>
            <div
              class="d-flex flex-column user-nav profile-general"
              style="margin-top: 5px"
            >
              <p class="user-name font-weight-bolder mb-0">
                {{ status.cancelados }}
              </p>
              <span class="user-status">Canceladas</span>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
</template>

<script>
import DashboardService from '@/services/DashboardService'
export default {
    name: "status-order",
    props:{
      client: Number
    },
    data(){
      return {
        status: null,
      }
    },
    mounted(){
      this.loadData();
    },
    methods:{
      loadData(){
        DashboardService.staticsTotalByStatus(this.client)
        .then(resp => {
          const { data } = resp.data;
          this.status = data;
        })
        .catch(err => console.log(err))
      }
    },
    watch: {
    client: function() {
      this.loadData();
    },
  },
    
}
</script>

<style scoped>
.initial-badge {
  border-radius: 50%;
  padding: 6px !important;
  padding-top: 5px !important;
  border-style: solid !important;
  border-width: 8px !important;
  background-color: #f68d1e;
  color: #ffffff;
  font-weight: 900;
  font-size: 15px;
  height: 50px;
  max-width: 50px;
  margin-left: 10px;
}

.initial-badge-error {
  border-radius: 50%;
  padding: 6px !important;
  padding-top: 5px !important;
  border-style: solid !important;
  border-width: 8px !important;
  background-color: #ea5455;
  color: #ffffff;
  font-weight: 900;
  font-size: 15px;
  height: 50px;
  max-width: 50px;
  margin-left: 10px;
}
</style>