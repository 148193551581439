<template>
  <b-card header-tag="header" v-if="user.type === 'pre-pago'  && advance< 100 && !!user.keopsUser">
    <h6 slot="header" class="mb-0">CONFIGURACION DEL PERFIL</h6>
    <div>
      <b-progress show-progress class="perfil-progres">
        <b-progress-bar :label="`${ advance }%`" :value="advance"></b-progress-bar>
      </b-progress>
      <div>
        <br />
        <p>
          <i :class="advance === 100 ? 'fa fa-plus':'fa fa-minus'" aria-hidden="true"></i>
          <span class="ml-2 text-muted">Formulario de Registro Completado</span>
        </p>
        <p>
          <i :class="!!user.keopsUser ? 'fa fa-plus':'fa fa-minus'" aria-hidden="true"></i>
          <span class="ml-2 text-muted">Informacion de Facturación Validada</span>
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "CardProfileFullComponent",
  data() {
    return {
      advance: this.$store.state.user.advance,
      isAdminOrStaff: UserService.isAdminOrStaff(),
      isCustomer: UserService.isCustomer(),
      user: this.$store.state.user
    };
  }
};
</script>