import axios from 'axios';
import moment from 'moment';


export default class DashboardService {

    /**
     * @description operationes por estados 
     */
    static statisticsByStates(year = null, month = null) {
        const day = new moment({ year, month, day: 1 });
        const end = new moment({ year, month, day: 1 }).endOf("month");
        const params = { from: day.format("YYYY-MM-DD"), to: end.format("YYYY-MM-DD") };
        return axios.get(process.env.VUE_APP_API + '/statistics/by-state', { params });
    }

    /**
     * @description pending
     */
    static statisticsByChannel(year = null, month = null, client = null) {
        const day = new moment({ year, month, day: 1 });
        const end = new moment({ year, month, day: 1 }).endOf("month");
        const params = { from: day.format("YYYY-MM-DD"), to: end.format("YYYY-MM-DD"), store_id: client };
        return axios.get(process.env.VUE_APP_API + '/statistics/by-channel', { params });
    }


    /**
     * @description pending
     */
    static statisticsByType(year = null, month = null, client = null) {
        const day = new moment({ year, month, day: 1 });
        const end = new moment({ year, month, day: 1 }).endOf("month");
        const params = { from: day.format("YYYY-MM-DD"), to: end.format("YYYY-MM-DD"), store_id: client };
        return axios.get(process.env.VUE_APP_API + '/statistics/by-type', { params });
    }

    /**
     * @description pending
     */
    static statisticsByProvince(year = null, month = null, client = null) {
        const day = new moment({ year, month, day: 1 });
        const end = new moment({ year, month, day: 1 }).endOf("month");
        const params = { from: day.format("YYYY-MM-DD"), to: end.format("YYYY-MM-DD"), store_id: client };
        return axios.get(process.env.VUE_APP_API + '/statistics/by-province', { params })
    }

    /**
     * @description pending
     */
    static statisticsByCustomer(type, from, to) {
        return axios.get(process.env.VUE_APP_API + '/statistics/by-customer', {
            params: { type: type, from, to }
        });
    }

    /**
     * @description pending
     */
    static statisticsretirementDay(year, month, client = null) {
        const params = { year, month, store_id: client };
        return axios.get(process.env.VUE_APP_API + '/statistics/retirement-day', { params });
    }

    /**
     * 
     */
    static staticsTransactionTotal(client) {
        const params = { store_id: client };
        return axios.get(process.env.VUE_APP_API + `/statistics/statics-transactions-total`, { params });
    }

    /**
     * 
     */
    static staticsTransactionTotalDiary() {
        return axios.get(process.env.VUE_APP_API + '/statistics/statics-transactions-total-diary');
    }

    /**
     * 
     */
    static staticsUserPrePagoTotal() {
        return axios.get(process.env.VUE_APP_API + '/statistics/statics-user-pre-pago-total');
    }

    /**
     * 
     */
    static staticsUserPostPagoTotal() {
        return axios.get(process.env.VUE_APP_API + '/statistics/statics-user-post-pago-total');
    }

    /**
     * 
     */
    static staticsUserClosureTotal(store_id) {
        const params = { store_id };
        return axios.get(process.env.VUE_APP_API + '/statistics/statics-user-closure-total', { params });
    }

    /**
     * 
     */
    static staticsTotalByStatus(client) {
        const params = { store_id: client };
        return axios.get(process.env.VUE_APP_API + '/statistics/statics-total-by-status', { params });
    }

}