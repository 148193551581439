<template>
  <b-card
    no-body
    style="
      background: linear-gradient(45deg, #321fdb, #1f1498) !important;
      background-image: linear-gradient(
        45deg,
        rgb(50, 31, 219),
        rgb(31, 20, 152)
      ) !important;
    "
  >
    <div class="p-1">
      <h4 class="text-white mb-0">
        {{ total | currency("", ".", "", "", "") }}
      </h4>
      <p class="text-white">Total de Envios</p>
      <line-total
        chartId="card-chart-transactions-total"
        :height="80"
        :datasets="datasets"
        :labels="labels"
        v-if="datasets"
      />
    </div>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import LineTotal from "./LineTotal";
import moment from "moment";

export default {
  name: "TransactionTotal",
  components: { LineTotal },
  props: {
    client: Number,
  },
  data() {
    return {
      total: 0,
      datasets: null,
      labels: null,
    };
  },
  methods: {
    loadData() {
      DashboardService.staticsTransactionTotal(this.client)
        .then(({ data }) => {
          const { total, months } = data.data;
          this.total = total;

          if (months.length > 0 && months.length < 6) {
            const dayA = moment({
              year: months[0].year,
              month: months[0].month,
              day: 1,
            }).subtract("months", 1);
            const dayB = moment({
              year: months[0].year,
              month: months[0].month,
              day: 1,
            }).subtract("months", 7 - months.length);
            while (dayA.isAfter(dayB)) {
              months.unshift({
                month: dayA.month(),
                year: dayA.year(),
                total: 0,
              });
              dayA.subtract("month", 1);
            }
          }
          this.datasets = [
            {
              label: "operaciones",
              backgroundColor: "transparent",
              borderColor: "rgba(255,255,255,.55)",
              data: months.map((d) => d.total),
            },
          ];
          this.labels = months.map((d) => d.month);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    client: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
