<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <card-profile-full-component />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="6" :lg="isAdmin ? 3 : 6" v-if="isAdmin || isCustomer">
        <transaction-total :client="getClient" />
      </b-col>

      <b-col sm="6" lg="6" v-if="isCustomer">
        <transaction-total-diary />
      </b-col>

      <b-col sm="6" lg="3" v-if="isAdmin">
        <pre-pago-total />
      </b-col>

      <b-col sm="6" lg="3" v-if="isAdmin">
        <post-pago-total />
      </b-col>

      <b-col sm="6" lg="3" v-if="isAdmin">
        <closure-total :client="getClient" />
      </b-col>
    </b-row>

    <b-row v-if="isAdmin || isCustomer">
      <b-col sm="12" md="12" lg="12" class="p-1">
        <status-order :client="getClient"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="6" lg="4" v-if="isAdmin || isCustomer">
        <by-channel :year="getYear" :month="getMonth" :client="getClient" />
      </b-col>

      <b-col sm="6" lg="4" v-if="isAdmin">
        <by-type :year="getYear" :month="getMonth" :client="getClient" />
      </b-col>

      <b-col sm="6" lg="4" v-if="isAdmin || isCustomer">
        <by-province :year="getYear" :month="getMonth" :client="getClient" />
      </b-col>

      <b-col sm="6" lg="4" v-if="isCustomer">
        <by-status :year="getYear" :month="getMonth" :client="getClient" />
      </b-col>
    </b-row>

    <b-row v-if="isAdmin || isCustomer">
      <b-col md="12">
        <card-daily-shipments-component :year="getYear" :month="getMonth" :client="getClient" />
      </b-col>
    </b-row>

    <b-row v-if="isAdmin">
      <b-col md="6">
        <card-customer-component type="pre-pago" v-if="isAdmin" />
      </b-col>

      <b-col md="6">
        <card-customer-component type="post-pago" v-if="isAdmin" />
      </b-col>
    </b-row>

    <b-row v-if="isReseller || isStaff">
      <b-col lg="12" class="mt-4 ml-4 pr-4">
        <img
          src="/img/brand/logo.png"
          alt="Enviamelo Logo"
          class="rounded mt-4 ml-4"
          style="height: 75px; width: 500px"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

import CardCustomerComponent from "./components/CardCustomerComponent";

import CardDailyShipmentsComponent from "./components/CardDailyShipmentsComponent";
import CardProfileFullComponent from "./components/CardProfileFullComponent";

import TransactionTotal from "./components/TransactionTotal";
import TransactionTotalDiary from "./components/TransactionTotalDiary";
import PrePagoTotal from "./components/PrePagoTotal";
import PostPagoTotal from "./components/PostPagoTotal";
import ClosureTotal from "./components/ClosureTotal";
// import BarSelectDate from "./components/BarSelectDate";
import ByChannel from "./components/ByChannel";
import ByProvince from "./components/ByProvince";
import ByType from "./components/ByType";
import ByStatus from "./components/ByStatus";
import AlertService from "@/services/AlertService";
import StatusOrder from "./components/StatusOrders"

import moment from "moment";
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: "dashboard",
  components: {
    CardCustomerComponent,
    CardDailyShipmentsComponent,
    CardProfileFullComponent,

    //////////////////////////////
    TransactionTotal,
    PrePagoTotal,
    PostPagoTotal,
    ClosureTotal,
    // BarSelectDate,
    StatusOrder,
    ByChannel,
    ByProvince,
    ByType,
    ByStatus,
    TransactionTotalDiary,
  },
  data() {
    return {
      advance: this.$store.state.user.advance,
      user: this.$store.state.user,

      isAdmin: UserService.isAdmin(),
      isReseller: UserService.isReseller(),
      isStaff: UserService.isStaff(),
      isCustomer: UserService.isCustomer(),
      month: moment().month(),
      year: moment().year(),
    };
  },
  mounted() {
    this.setYear(this.year);
    this.setMonth(this.month);
    this.setClient(null);
    this.setPageDashboard(true);

    if (UserService.isReseller() || UserService.isStaff()) {
      this.$router.push("/dashboard/operations");
    }
    // in_progress_keops
    if (this.user.type === "pre-pago" && this.user.in_progress_keops) {
      this.$swal({
        type: "warning",
        title: "Usuario Incompleto. ",
        text: "Aun no hemos validado la información de facturación de tu cuenta. Este proceso puede demorar de 1 a 5 minutos. Te enviaremos un email una vez que hayamos completado la validación.",
        confirmButtonColor: "#f68d1e",
        customClass: AlertService.customClass,
      });
    } else if (this.user.type === "pre-pago" && this.user.advance <= 95) {
      const text = "Tu perfil debe estar al 100% para poder realizar envios";
      this.$swal({
        type: "warning",
        title: "Usuario Incompleto. ",
        text: text,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Editar perfil",
        confirmButtonColor: AlertService.confirmButtonColor,
        cancelButtonColor: AlertService.cancelButtonColor,
        customClass: AlertService.customClass,
      }).then(({ value }) => {
        if (value) {
          this.$router.push("/dashboard/settings/profile");
        }
      });
    }
  },
  computed:{
    ...mapGetters('dashboard', ['getYear', 'getMonth', 'getClient'])
  },
  methods:{
    ...mapMutations('dashboard', ['setYear', 'setMonth', 'setClient', 'setPageDashboard']),
  },
  destroyed(){
    this.setPageDashboard(false);
  }
};
</script>
