<template>
  <b-card
    no-body
    style="background: #2982CC!important; background: linear-gradient(45deg,#39f,#2982CC)!important; border-color: #2982CC!important;"
  >
    <div class="p-1">
      <h4 class="text-white mb-0">{{total | currency('', '.','', '','',)}}</h4>
      <p class="text-white">Total de Envios de la semana</p>
      <line-total
        chartId="card-chart-transactions-total-day"
        :height="80"
        :datasets="datasets"
        :labels="labels"
        :days="true"
        v-if="datasets"
      />
    </div>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import LineTotal from "./LineTotal";
import moment from "moment";

export default {
  name: "TransactionTotalDiary",
  components: { LineTotal },
  props: {
    client: Number 
  },
  data() {
    return {
      total: 0,
      datasets: null,
      labels: null
    };
  },
  mounted() {
    DashboardService.staticsTransactionTotalDiary()
      .then(({ data }) => {
        const { total, months } = data.data;
        this.total = total;
        const elements = [6, 5, 4, 3, 2, 1, 0].map(i => {
          const today = moment().subtract("days", i);
          const e = months.find(e => {
            return today.date() === e.day;
          });
          if (e) {
            return {
              year: today.year(),
              month: today.month(),
              date: today.date(),
              day: today.day(),
              total: e.total
            };
          }
          return {
            year: today.year(),
            month: today.month(),
            date: today.date(),
            day: today.day(),
            total: 0
          };
        });
        this.datasets = [
          {
            label: "operaciones",
            backgroundColor: "transparent",
            borderColor: "rgba(255,255,255,.55)",
            data: elements.map(d => d.total)
          }
        ];
        this.labels = elements.map(d => d.day);
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style>
</style>