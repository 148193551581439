<template>
  <card-doughnut-component
    :chartdata="data"
    :title="'Cantidad de envios por Tipo'"
    :loading="loading"
  />
</template>

<script>
import CardDoughnutComponent from "./CardDoughnutComponent";
import DashboardService from "@/services/DashboardService";

export default {
  name: "by-type",
  props: {
    year: Number,
    month: Number,
    client: Number
  },
  data() {
    return {
      data: {},
      loading: true
    };
  },
  components: {
    CardDoughnutComponent
  },
  methods: {
    loadData() {
      this.loading = true;
      DashboardService.statisticsByType(this.year, this.month, this.client).then(
        ({ data }) => {
          this.loading = false;
          const elements = data.data;
          this.data = {
            labels: elements.map(d => d.type),
            datasets: [
              {
                data: elements.map(d => d.total),
                backgroundColor: [
                  "#ff6384",
                  "#4bc0c0",
                  "#ffcd56",
                  "#c9cbcf",
                  "#36a2eb"
                ]
              }
            ]
          };
        }
      );
    }
  },
  watch: {
    year: function() {
      this.loadData();
    },
    month: function() {
      this.loadData();
    },
    client: function() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  }
};
</script> 