<template>
  <b-card
    no-body
    style="
      background: #2982cc !important;
      background: linear-gradient(45deg, #39f, #2982cc) !important;
      border-color: #2982cc !important;
    "
  >
    <div class="p-1">
      <h4 class="text-white mb-0">
        {{ total | currency("", ".", "", "", "") }}
      </h4>
      <p class="text-white">Total de Tiendas Pre Pago</p>
      <line-total
        chartId="card-chart-pre-pago"
        :height="80"
        :datasets="datasets"
        :labels="labels"
        v-if="datasets"
      />
    </div>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import LineTotal from "./LineTotal";

export default {
  name: "PrePagoTotal",
  components: { LineTotal },
  data() {
    return {
      total: 0,
      datasets: null,
      labels: null,
    };
  },
  methods: {
    loadData() {
      DashboardService.staticsUserPrePagoTotal()
        .then(({ data }) => {
          const { total, months } = data.data;
          this.total = total;
          this.datasets = [
            {
              label: "pre-pago",
              backgroundColor: "transparent",
              borderColor: "rgba(255,255,255,.55)",
              data: months.map((d) => d.total),
            },
          ];
          this.labels = months.map((d) => d.month);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style></style>
