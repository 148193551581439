<template>
  <b-card
    no-body
    style="background: rgb(229,83,83); background: linear-gradient(90deg, rgba(229,83,83,1) 0%, rgba(224,76,76,1) 50%, rgba(176,51,51,1) 100%"
  >
    <div class="p-1">
      <h4 class="text-white mb-0">{{ total | currency }}</h4>
      <p class="text-white" style="font-size: 12px">
        Facturación por total de envios
      </p>
      <line-total
        chartId="card-chart-total"
        :height="80"
        :datasets="datasets"
        :labels="labels"
        v-if="datasets"
      />
    </div>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import LineTotal from "./LineTotal";

export default {
  name: "PostPagoTotal",
  components: { LineTotal },
  props: {
    client: Number,
  },
  data() {
    return {
      total: 0,
      datasets: null,
      labels: null,
    };
  },
  methods: {
    loadData() {
      DashboardService.staticsUserClosureTotal(this.client)
        .then(({ data }) => {
          const { total, months } = data.data;
          this.total = total;
          this.datasets = [
            {
              label: "Monto",
              backgroundColor: "transparent",
              borderColor: "rgba(255,255,255,.6)",
              data: months.map((d) => d.sum.toFixed(2)),
            },
          ];
          this.labels = months.map((d) => d.month);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  watch: {
    client: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
 
<style>
</style>