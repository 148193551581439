<template>
  <b-card header-tag="header" class="px-1 pt-0 pb-1">
    <h6 slot="header" class="mb-0">{{title}}</h6>
    <b-card-body class="pb-0 text-center" v-if="loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </b-card-body>
    <b-card-body class="p-0" v-else>
      <b-alert
        show
        variant="warning"
        v-if="chartdata.datasets[0].data.length == 0"
      >No hay datos para mostrar</b-alert>
      <doughnut-component :chartData="chartdata" :options="options" :height="300" v-else></doughnut-component>
    </b-card-body>
  </b-card>
</template>

<script>

import DoughnutComponent from "@/layouts/components/DoughnutComponent";

export default {
  name: "card-doughnut-component",
  props: {
    title: String,
    loading: Boolean,
    chartdata: Object
  },
  components: {
    DoughnutComponent
  },
  mounted() {},
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: "bottom" }
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
