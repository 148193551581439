<template>
  <b-card :header=" 'Top Tiendas ' + type " class="px-1 pb-1 pt-0">
    <b-card-body class="pb-0 text-center" v-if="loading">
      <b-spinner variant="primary"></b-spinner>
    </b-card-body>
    <b-card-body class="p-0" v-else>
      <b-row>
        <b-col sm="12" v-if="data">
          <b-form inline>
            <b-form-select
              size="sm"
              class="select-date-custom mb-0 mr-1 mb-2"
              v-model="year"
              :options="years"
              @change="onChangeData"
            ></b-form-select>
            <b-form-select
              size="sm"
              class="select-date-custom mb-0 mr-1 mb-2"
              v-model="month"
              @change="onChangeData"
            >
              <option :value="null">seleccione</option>
              <option value="0">Enero</option>
              <option value="1">Febrero</option>
              <option value="2">Marzo</option>
              <option value="3">Abril</option>
              <option value="4">Mayo</option>
              <option value="5">Junio</option>
              <option value="6">Julio</option>
              <option value="7">Agosto</option>
              <option value="8">Septiembre</option>
              <option value="9">Octubre</option>
              <option value="10">Noviembre</option>
              <option value="11">Diciembre</option>
            </b-form-select>
          </b-form>
        </b-col>
        <b-col sm="12" v-if="data">
          <div class="progress-group mb-4" v-for="item in data.users" v-bind:key="item.id">
            <div class="progress-group-prepend">
              <span class="progress-group-text">{{item.brand}}</span>
            </div>
            <div class="progress-group-bars">
              <small>
                Cantidad de envios
                <b>{{item.quantity}}</b>
              </small>
              <b-progress
                height="1rem"
                class="progress-xs"             
                variant="info"
                :value="item.quantity"
                :max="data.quantity_max"
              />
              <small>
                <b>{{item.total | currency }}</b>
              </small>
              <b-progress
                height="1rem"
                class="progress-xs"
                variant="success"
                :value="item.total"
                :max="data.total_max"
              />
            </div>
          </div>

          <div v-if="data.quantity_max">
            <small>Referencia de envios: {{ parseFloat( data.quantity_max).toFixed(1) }}.</small>
            <small class="ml-2">({{data.total_max | currency }})</small>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import DashboardService from "@/services/DashboardService";
import moment from "moment";

export default {
  name: "card-customer-component",
  props: ["type"],
  mounted() {
    this.loadData();
  },
  data() {
    return {
      selected: null,
      data: null,
      loading: true,
      month: null,
      year: null,
      years: [
        { value: null, text: "seleccione" },
        ...Array(5)
          .fill(2019)
          .map((v, i) => {
            return { value: v + i, text: v + i };
          })
      ]
    };
  },
  methods: {
    /**
     * Load Data
     */
    loadData() {
      this.loading = true;
      DashboardService.statisticsByCustomer(this.type).then(({ data }) => {
        this.data = data.data;
        this.loading = false;
      });
    },

    /**
     *
     */
    onChangeData() {
      this.loading = true;
      const day = new moment({
        year: this.year,
        month: this.month,
        day: 1
      });
      const end = new moment({
        year: this.year,
        month: this.month,
        day: 1
      }).endOf("month");
      DashboardService.statisticsByCustomer(
        this.type,
        day.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      ).then(({ data }) => {
        this.data = data.data;
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.progress{
  background-color: rgba(227, 228, 230, 0.8) !important;
}
</style>