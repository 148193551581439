<template>
  <b-card header-tag="header" class="px-1 py-0">
    <h6 slot="header" class="mb-0" v-if="isAdmin">Envíos Diarios y Facturación</h6>
    <h6 slot="header" class="mb-0" v-else>Envíos Diarios</h6>
    <b-card-body class="pb-0 text-center" v-if="loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </b-card-body>
    <b-card-body class="pb-0" v-else>
      <div class="ml-2">
        <span>Envíos Diarios </span>
        <span class="pull-right" v-if="isAdmin">Facturación ($)</span>
      </div>
      <bar-component
        :chart-data="data"
        :options="isCustomer? optionsCustomer: options"
        :height="140"
        v-if="data"
      ></bar-component>
      <p class="text-center mt-1">
        <span>{{months[month]}}</span>
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BarComponent from "@/layouts/components/BarComponent";
import UserService from "@/services/UserService";
import DashboardService from "@/services/DashboardService";
import moment from "moment";

export default {
  name: "card-daily-shipments-component",
  components: { BarComponent },
  props: {
    year: Number,
    month: Number,
    client: Number
  },
  mounted() {
    this.loadData();
  },
  data() {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    return {
      isAdmin: UserService.isAdmin(),
      isCustomer: UserService.isCustomer(),
      loading: true,
      data: null,
      options: {
        tooltips: {
          callbacks: {
            title: tooltipItem => {
              return tooltipItem[0].label + " de " + months[this.month];
            },
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              if (tooltipItem.datasetIndex === 1) {
                label += this.$options.filters.currency(tooltipItem.yLabel);
              } else {
                label += tooltipItem.yLabel;
              }
              return label;
            }
          },
          enabled: true,
          mode: "label"
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          yAxes: [
            {
              id: "A",
              ticks: {
                beginAtZero: true,
                stepSize: 100
              },
              gridLines: {
                // display: false
              },
              position: "left"
            },
            {
              id: "B",
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  return this.$options.filters.currency(value);
                },
                stepSize: 25000
              },
              gridLines: {
                // display: false
              },
              position: "right"
            }
          ]
        }
      },
      optionsCustomer: {
        tooltips: {
          callbacks: {
            title: tooltipItem => {
              return tooltipItem[0].label + " de " + months[this.month];
            }
          }
        },
        legend: {
          display: true
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              },
              ticks: {
                beginAtZero: true
              }
            }
          ],
          yAxes: [
            {
              id: "A",
              ticks: {
                beginAtZero: true,
                stepSize: 1
              },
              gridLines: {
                //  display: false
              }
            }
          ]
        }
      },
      months
    };
  },
  methods: {
    loadData() {
      const day = moment({ year: this.year, month: this.month, day: 1 });
      this.loading = true;
      DashboardService.statisticsretirementDay(this.year, day.month()+1, this.client)
        .then(response => {
          this.loading = false;
          const elements = response.data.data;
          const days = Array(day.daysInMonth())
            .fill(0)
            .map((v, i) => {
              return i + 1;
            });

          const quantities = Array(day.daysInMonth())
            .fill(0)
            .map((v, i) => {
              const value = elements.find(v => v.day === i + 1);
              if (value) {
                return value.quantity;
              }
              return 0;
            });

          if (this.isCustomer) {
            this.data = {
              labels: days,
              datasets: [
                {
                  label: "Cantidad de Envíos",
                  yAxisID: "A",
                  data: quantities,
                  backgroundColor: "#CEECF5",
                  borderColor: "#3399FF",
                  pointHoverBackgroundColor: "#fff"
                }
              ]
            };
            return;
          }

          const total = Array(day.daysInMonth())
            .fill(0)
            .map((v, i) => {
              const value = elements.find(v => v.day === i + 1);
              if (value) {
                return value.total;
              }
              return 0;
            });

          this.data = {
            labels: days,
            datasets: [
              {
                label: "Cantidad de Envíos",
                yAxisID: "A",
                data: quantities,
                backgroundColor: "#CEECF5",
                borderColor: "#3399FF",
                pointHoverBackgroundColor: "#fff"
              },
              {
                label: "Facturación",
                yAxisID: "B",
                data: total,
                backgroundColor: "#CEF6D8",
                borderColor: "#2EB85C",
                pointHoverBackgroundColor: "#fff"
              }
            ]
          };
        })
        .catch(error => {
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Eliminar`,
              text: `Error al tratar de obtener data`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    }
  },
  watch: {
    year: function() {
      this.loadData();
    },
    month: function() {
      this.loadData();
    },
    client: function() {
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
